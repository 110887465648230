import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Spinner from '../../../../components/template/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import api from '../../../../components/utils/api';

const DirectLink = ({ path, label }) => (
    <a
        href={path}
        target='_blank'
        rel="noopener noreferrer"
        title="redirect to the converted model"
    >
        <span className='text-center inline-block align-middle h-10 w-40 text-violet-500 bg-violet-100 border border-violet-400 rounded hover:bg-violet-200 p-2 m-1'>
            {label}
        </span>
    </a>
)

const ConvertBtnTemplate = ({ label, onClick, disabled, title = "" }) => (
    <button
        type='button'
        className='h-10 w-40 m-1 text-violet-500 bg-violet-100 border border-violet-400 rounded hover:bg-violet-200 disabled:border-gray-300 disabled:bg-gray-50 disabled:text-gray-400 disabled:cursor-not-allowed'
        onClick={onClick}
        disabled={disabled}
        title={title}
    >{label}</button>
)

const ConvertPage = () => {
    const [convertedModels, setConvertedModels] = useState({})
    const [convertBtnDisabled, setConvertBtnDisabled] = useState(false)
    const { state } = useLocation();

    const onInit = useCallback(() => {
        api.get(`/api/post-action/merge-rules/${state.selectedMergeRule.id}/converted-models?general_model_id=${state.generalModels.id}`)
            .then(res => setConvertedModels({ ...res.data.converted_models }))
            .catch(err => console.error(err))
    }, [state])

    const onConvert = (convertModel) => {
        setConvertBtnDisabled(true)
        const frameworkDataType = convertModel.split("_")
        api.post(`/api/post-action/merge-rules/${state.selectedMergeRule.id}/converted-models`, { general_model_id: state.generalModels.id, framework: frameworkDataType[0], data_type: frameworkDataType[1] })
            .then(() => {
                onInit()
            })
            .catch(err => console.error(err))
    }

    useEffect(() => {
        onInit()
    }, [onInit])

    return (
        <div className='p-1'>
            <div className='text-right m-1'>
                <button type='button' className='text-sm text-gray-400' onClick={() => onInit()}>Refresh <FontAwesomeIcon icon={faRotate} /></button>
            </div>
            <div>
                {Object.keys(convertedModels).map((e, i) => <div key={i} className='md:flex md:justify-between items-center text-left'>
                    <div className='font-bold'>{e.toString()}</div>
                    <div>
                        {Object.keys(convertedModels[e]).length === 0 || convertedModels[e].status === 5
                            ? <ConvertBtnTemplate label={convertedModels[e].status === 5 ? "Converted" : "Convert"} onClick={() => onConvert(e)} disabled={convertBtnDisabled || !(Object.keys(convertedModels[e]).length === 0)} />
                            : <ConvertBtnTemplate label={<div className='flex m-auto'>
                                <div className='pt-0.5 pl-2'><Spinner /></div>
                                <div className='m-auto cursor-default text-xs'>{convertedModels[e].status_message}</div>
                            </div>} onClick={() => onConvert(e)} disabled={true} />
                        }
                        {[
                            { label: 'Google Storage', path: `https://console.cloud.google.com/storage/browser/${convertedModels[e].path}` },
                            { label: 'Model Testing', path: e.includes('tflite') || e.includes('pb') ? `${process.env.REACT_APP_MODEL_TESTING_DOMAIN}?db=${state.dbVersion}&experiment=${state.expVersion}&module=${state.dbVersion}_${state.expVersion}_${state.modelType}&rule=${state.selectedMergeRule.name}` : "" },
                        ].map((ele, i) => {
                            return (Object.keys(convertedModels[e]).length === 0 || convertedModels[e].status !== 5 || (ele.label === 'Model Testing' && e.includes('int8')))
                                ? (<ConvertBtnTemplate label={ele.label} onClick={() => { }} disabled={true} title={ele.label === 'Model Testing' && e.includes('int8') ? "The model testing tool supports float data types only" : "To use this button, please convert the model first"} key={i} />)
                                : (<DirectLink path={ele.path} label={ele.label} key={i} />)
                        })}

                    </div>
                </div>)}
            </div >
        </div>
    )
}

export default ConvertPage