import Select from 'react-select'

const ServiceGroupSelector = ({ serviceGroups, setServiceGroup }) => {
    return (
        <Select
            options={serviceGroups.map(e => ({ label: e, value: e }))}
            onChange={e => { setServiceGroup(undefined); setServiceGroup(e.value) }}
        />
    )
}

export default ServiceGroupSelector