import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowRightFromBracket,
    faBoxArchive,
    faDatabase,
    faDownLeftAndUpRightToCenter,
    faMicrochip,
    faTruckRampBox
} from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import ButtonWrap from '../../components/template/ButtonWrap';
import { useEffect, useState } from 'react';

const MainPage = () => {
    const [expDate, setExpDate] = useState()
    const navigate = useNavigate()

    useEffect(() => {
        try {
            setExpDate(localStorage.getItem('expiredAt'))
        } catch {
            navigate('/signin')
        }
    }, [navigate])
    return (
        <div className='default-background'>
            <div className="text-right">
                <p className='text-gray-400 p-1'>Login expires at: {expDate}</p>
                <ButtonWrap contents={<p><FontAwesomeIcon icon={faArrowRightFromBracket} /> Log out</p>} onClick={() => navigate('/signin')} />
            </div>
            <div className='grid items-center justify-center'>
                <div className='self-center pb-12'>
                    <img src='eats_logo.png' alt='logo' className='m-auto' />
                </div>
                <div className='md:grid md:grid-cols-3 md:gap-x-6 w-full mx-auto my-4 px-8'>
                    {[
                        { title: 'Database', icon: faDatabase, to: '/db-generation' },
                        { title: 'Preaction', icon: faMicrochip, to: '/training' },
                        { title: 'Post Action', icon: faTruckRampBox, to: '/model-selection' },
                        { title: 'Evaluation Comparison', icon: faDownLeftAndUpRightToCenter, to: '/evaluation-comparison' },
                        { title: 'Service Archive', icon: faBoxArchive, to: '/service-archive' },
                    ].map((e, i) => <Link to={e.to} key={i}>
                        <div className='h-32 my-4 grid grid-cols-4 items-center rounded border px-10 py-8 hover:border-2 hover:border-gray-600 hover:bg-gray-500 hover:text-white'>
                            <FontAwesomeIcon icon={e.icon} size='3x' />
                            <div className='col-span-3 text-2xl select-none'>{e.title}</div>
                        </div>
                    </Link>)}
                </div>
            </div>
        </div>
    )
}

export default MainPage