import { useMemo } from "react"
import Table, { SelectColumnFilter } from "../../components/table/Table"
import DirectLink from "../../components/template/DirectLink"
import Spinner from "../../components/template/Spinner"

const ServiceArchiveTable = ({ data }) => {
    const columns = useMemo(
        () => [
            {
                Header: "ID",
                accessor: "id",
            },
            {
                Header: "Service Name",
                accessor: "name",
                Filter: SelectColumnFilter,
                filter: 'exactTo',
            },
            {
                Header: "Google Storage",
                accessor: "path",
                Cell: ({ row }) => (<>{
                    row.original.status === 1 ?
                        <DirectLink path={`https://console.cloud.google.com/storage/browser/${row.original.path}`} title={`Direct link to the google storage link for ${row.original.name} service of ${row.original.group_name}`} />
                        : <div className='h-10 w-32 text-gray-500 border border-gray-400 rounded text-xs m-auto flex'>
                            <div className='pt-2 pl-2'><Spinner /></div>
                            <div className='m-auto cursor-default'>Exporting...</div>
                        </div>
                }</>)
            },
            {
                Header: "Deploy",
                accessor: "deployment",
                Cell: ({ row }) => (<>{
                    row.original.status === 1 ?
                        <DirectLink path={`${process.env.REACT_APP_BIZ_DOMAIN}/models?service_id=${row.original.id}`} title={`Direct link for the model deployment`} />
                        : <div className='h-10 w-32 text-gray-500 border border-gray-400 rounded text-xs m-auto flex'>
                            <div className='pt-2 pl-2'><Spinner /></div>
                            <div className='m-auto cursor-default'>Exporting...</div>
                        </div>
                }</>)
            },
            {
                Header: "Model Test",
                accessor: "model_test",
                Cell: ({ row }) => (<>{
                    row.original.status === 1 ?
                        <DirectLink path={`${process.env.REACT_APP_MODEL_TESTING_DOMAIN}?db=${row.original.general_model.database_version}&experiment=${row.original.general_model.experiment_version}&module=${row.original.general_model.name}`} title={`Direct link for the model deployment`} />
                        : <div className='h-10 w-32 text-gray-500 border border-gray-400 rounded text-xs m-auto flex'>
                            <div className='pt-2 pl-2'><Spinner /></div>
                            <div className='m-auto cursor-default'>Exporting...</div>
                        </div>
                }</>)
            },
            {
                Header: "Model",
                accessor: "general_model.name",
            },
            {
                Header: "Database",
                accessor: "general_model.database_version",
                Filter: SelectColumnFilter,
                filter: 'exactTo'
            },
            {
                Header: "Model Type",
                accessor: "model_type_col",
                Cell: ({ row }) => <div>{row.original.model_type.join(", ")}</div>
            },
            {
                Header: "Converted Model",
                accessor: "converted_model.name",
            },
            {
                Header: "Threshold",
                accessor: "threshold.name",
            },
        ], []
    )

    return (
        <Table data={data} columns={columns} />
    )
}

export default ServiceArchiveTable