import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ButtonWrap from "../../components/template/ButtonWrap"
import MainTitle from "../../components/template/MainTitle"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import NewInfoFormat from "../../components/template/NewInfoFormat"
import ServiceGroupSelector from "./ServiceGroupSelector"
import api from "../../components/utils/api"
import ServiceArchiveTable from "./ServiceArchiveTable"

const ServiceArchivePage = () => {
    const [services, setServices] = useState([])
    const [serviceGroup, setServiceGroup] = useState()
    const [serviceGroups, setServiceGroups] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        const fetchServices = async () => {
            try {
                const responses = await api.get('/api/eats-services')
                setServices(responses.data.services)
            } catch (error) {
                console.log(error)
            }
        }
        fetchServices()
    }, [])

    useEffect(() => {
        const uniqueGroupNames = [...new Set(services.map(service => service.group_name))];
        const sortedGroupNames = uniqueGroupNames.sort();
        setServiceGroups(sortedGroupNames)
    }, [services])

    return (
        <div className='default-background'>
            <div className="flex justify-between items-center my-5">
                <MainTitle title="Service Archive" />
                <ButtonWrap contents={<FontAwesomeIcon icon={faArrowLeft} />} onClick={() => navigate('/')} />
            </div>
            <NewInfoFormat label="Service Group" description={<></>} contents={<ServiceGroupSelector serviceGroups={serviceGroups} setServiceGroup={setServiceGroup} />} />
            {serviceGroup && <ServiceArchiveTable data={services.filter(e => e.group_name === serviceGroup) || []} />}
        </div>)
}

export default ServiceArchivePage